import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getBaseName } from '../../../helpers/bundleHelpers';
import chevronUp from '../../../img/icons/ChevronUpThin.svg'

import CustomiseCard from './CustomiseCard';
import { RequiredTag } from '../../../components/RequiredTag';

const BundleCustomiseSection = ({
  bundleSection,
  customisedBundle,
  handleSelect,
  handleIncrease,
  handleDecrease,
  showErrors,
  invalidSections,
  setInvalidSections,
  compare,
  disabledItems,
  outOfStockItems,
  handleRequiredScrolling,
  showCalories
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { name } = bundleSection;
  const [error, setError] = useState();
  const [showSectionErrors, setShowSectionErrors] = useState(false);
  const { filters } = useSelector(state => state.menu);
  const [sortedProducts, setSortedProducts] = useState([...bundleSection.products].sort(compare));
  const [itemNumber, setItemNumber] = useState(0);
  const required = bundleSection.minItems === 1;

  const getItemNumber = () => {
    const section = customisedBundle?.sections.find(el => el.id === bundleSection.id);
    return section?.products.reduce((acc, cur) => acc + cur.product.quantity, 0);
  };

  useEffect(() => {
    setItemNumber(getItemNumber());
  }, [customisedBundle]);


  const checkSelected = (product) => {
    const exists = customisedBundle.sections.filter(section => section.id === bundleSection.id).reduce((acc, cur) => {
      const productName = `${getBaseName(product)}${product.product.name}`;
      const item = cur.products.find(p => productName === `${getBaseName(p)}${p.product.name}`);
      if (item) {
        acc = item.product;
      }
      return acc;
    }, false);
    return exists;
  };

  useEffect(() => {
    const invalid = invalidSections.find(el => el === bundleSection.id);
    if (invalid) {
      setError(true);
    } else {
      setError(false);
    }
  }, [invalidSections]);

  useEffect(() => {
    const customBundleSection = customisedBundle?.sections.find(el => el.id === bundleSection.id);
    if (customBundleSection?.products.reduce((partial, p) => partial + p.product.quantity, 0) < bundleSection.minItems) {
      setInvalidSections(sections => [...sections, bundleSection.id]);
    } else {
      setShowSectionErrors(true);
      setInvalidSections(sections => sections.filter(el => el !== bundleSection.id));
    }
  }, [customisedBundle]);

  const handleCardSelect = (product) => {
    handleSelect(product, customisedBundle, bundleSection.id, bundleSection.maxItems);
    if (required) handleRequiredScrolling(bundleSection.id);
  };

  const handleCardIncrease = (product) => {
    handleIncrease(product, customisedBundle, bundleSection.id, bundleSection.maxItems);
  };

  const handleCardDecrease = (product) => {
    handleDecrease(product, customisedBundle, bundleSection.id);
  };

  const handleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    let newProducts = [...bundleSection.products].sort(compare);
    setSortedProducts(newProducts.sort(compare));
  }, [bundleSection?.products]);

  return (
    <>
      <div className='customise-section' data-section-error-id={error ? bundleSection.id : null}>
        <div className='customise-section-title-container'>
          <div className='customise-section-header-container'>
            <h2 className='title customise-section-header' style={{ lineHeight: '1.2' }}>{name}</h2>
            {
              required ?
                <RequiredTag isErrored={error} showError={showErrors ? true : showSectionErrors} />
                :
                <img
                  src={chevronUp}
                  role='icon'
                  style={{ transform: !isExpanded ? 'rotate(180deg)' : '', cursor: 'pointer' }}
                  onClick={() => { handleAccordion() }}
                />
            }
          </div>
        </div>
        {
          isExpanded &&
          <div className='customise-card-option-container'>
            {
              sortedProducts
                .filter(el => filters.every(pref => el.product.dietPreferences.includes(pref)))
                .filter(el => !el.product.outOfStock)
                .map((el, idx) => {
                  return (
                    <CustomiseCard
                      index={idx}
                      name={getBaseName(el) ? `${el.product.name} ${getBaseName(el)}` : el.product.name}
                      cost={el.cost}
                      bundleProduct
                      option={el}
                      selected={checkSelected(el)}
                      cardSelect={() => handleCardSelect(el)}
                      handleIncrease={() => handleCardIncrease(el)}
                      handleDecrease={() => handleCardDecrease(el)}
                      reachedMax={itemNumber >= bundleSection.maxItems}
                      key={idx}
                      disabled={disabledItems.includes(el.product.id)}
                      parent={bundleSection}
                      required={required}
                      showCalories={showCalories}
                    />
                  )
                }
                )
            }
          </div>
        }
      </div>
    </>
  );
};

export default BundleCustomiseSection;